import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import { faArrowLeft, faFileInvoiceDollar, faCartShopping, faCircleCheck, faCreditCard, faMoneyBill1Wave, faMoneyBillTransfer, faCalendar, faTriangleExclamation, faCopy, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import Nav from '../../hooks/Nav';
import HeadersDataTables from '../../hooks/HeadersDataTables';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import Button from '../../hooks/Button';
import DatePicker from 'react-modern-calendar-datepicker';
import useConvertDatePicker from '../../hooks/useConvertDatePicker';
import useReverseDatePicker from '../../hooks/useReverseDatepicker';
import useFormatDate from '../../hooks/useFormatDate';
import useGetDate from '../../hooks/useGetDate';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import Tooltip from '../../hooks/Tooltip';
import moment from 'moment';
import { AlertContext } from '../../context/AlertContext';
import Select from '../../hooks/Select';
import { AuthContext } from '../../cognito/AuthProvider';
import { capitalize } from '../../tools/stringFormatter';
import BreadCrumb from '../../tailwindUI/BreadCrumb';
import usePaymentRules from '../../hooks/usePaymentRules';
import Toogle from '../../tailwindUI/Toogle';
import { InformationCircleIcon, TagIcon } from '@heroicons/react/24/solid';
import PermissionsGate from '../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../cognito/permissions/permission-maps';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getServicesDebt } from '../../apiClient/operations/collectionOperations';
import useConstructDatesDisplay from '../../hooks/useConstructDatesDisplay';
import TableBillingLink from '../portfolio/TableBillingLink';
import { formatPeso } from '../../util';
import SkeletonLoader from '../../tailwindUI/SkeletonLoader';
import { createPaymentLink } from '../../apiClient/operations/paymentLinksOperations';

const roadMap = [
    { name: 'Cobranza', url: '/portfolio/collecting', current: false },
    { name: 'Generando link de cobro', url: '/portfolio/collecting/createLink', current: true },
];

const paymentMethods = [
    {
        id: 1,
        value: "card",
        name: "Tarjeta de crédito o débito",
        shortName: "Tarjeta",
        icon: faCreditCard,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/aceptamos-pago-con-tarjeta.png"
    },
    {
        id: 2,
        value: "paypal",
        name: "Pago con Paypal",
        shortName: "Paypal",
        icon: faPaypal,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/paypal.png"
    },
    {
        id: 3,
        value: "cash",
        name: "Pago en fectivo",
        shortName: "Efectivo",
        icon: faMoneyBill1Wave,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/oxxo_short.svg"
    },
    {
        id: 4,
        value: "transfer",
        name: "Transferencia bancaria",
        shortName: "Transferencia",
        icon: faMoneyBillTransfer,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/spei.png"
    }
];

const paymentInterval = [
    {
        id: 1,
        name: "Mensual",
        interval: "month",
        frequency: 1
    },
    {
        id: 2,
        name: "Semanal",
        interval: "week",
        frequency: 1
    },
    {
        id: 3,
        name: "Quincenal",
        interval: "half_month",
        frequency: 1
    },
    {
        id: 4,
        name: "Bimestral",
        interval: "month",
        frequency: 2
    },
    {
        id: 5,
        name: "Trimestral",
        interval: "month",
        frequency: 3
    },
    {
        id: 6,
        name: "Semestral",
        interval: "month",
        frequency: 6
    },
    {
        id: 7,
        name: "Anual",
        interval: "year",
        frequency: 1
    }
];

const paymentTypes = [
    {
        id: 1,
        name: "Cobro único",
        allowedPaymentMethods: ["card", "paypal", "cash", "transfer"]
    },
    {
        id: 2,
        name: "Cobro recurrente",
        allowedPaymentMethods: ["card"]
    }
]


function CreatePaymentBilling() {

    const { user } = React.useContext(AuthContext);
    let history = useHistory();
    const { formatterCurrency } = useFormatterCurrency();
    const { convertDatePicker } = useConvertDatePicker();
    const { reverseDatePicker } = useReverseDatePicker();
    const { getDate } = useGetDate();
    const { formatDate } = useFormatDate();
    const { installments: getInstalmentsEnabled } = usePaymentRules();
    const { setAlert } = React.useContext(AlertContext);
    const [account, setAccount] = useState(null);
    const [concept, setConcept] = useState("");
    const [comment, setComment] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([paymentMethods[0]]);
    const [msi, setMsi] = useState([]);
    const [selectedMsi, setSelectedMsi] = useState(getInstalmentsEnabled(0)[0]);
    const [serviceDate, setServiceDate] = useState(convertDatePicker(formatDate(moment().add(7, 'days'), 'YYYY-MM-DD')));
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [renovationUnselected, setRenovationUnselected] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [paymentLink, setPaymentLink] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const [shortLinkCopied, setShortLinkCopied] = useState(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState(paymentTypes[0]);
    const [selectedPaymentInterval, setSelectedPaymentInterval] = useState(paymentInterval[0]);
    const [subscriptionDate, setSubscriptionDate] = useState(convertDatePicker(formatDate(moment().add(1, 'M').startOf('month'), 'YYYY-MM-DD')));
    const [numberOfPayments, setNumberOfPayments] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [enabledDisccount, setEnabledDisccount] = useState(false);
    const [applyCharge, setApplyCharge] = useState(true);
    const [applyChargeDisabled, setApplyChargeDisabled] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const { state } = useLocation()

    useEffect(() => {
        setLoadingInfo(true)
        getServicesDebt(state).then((response) => {
            setLoadingInfo(false)
            let servArray = []
            setAccount(response)
            response.line_items.map((item) => {
                const serv = {
                    ...item,
                    qty: item?.overdue,//item?.periods?.length,
                    remaining_amount: item?.overdue * item?.price,
                    dates_display: useConstructDatesDisplay(item?.periods_overdue),
                    discount: 0,
                    discount_value: 0,
                    renovation: false,
                    selected: true,
                    disccount_type: "direct",
                    subscription_price: item?.price / 100
                }
                servArray.push(serv)
            })
            setServices(servArray);
            setSelectedServices(servArray)
        }).catch((error) => {
            setLoadingInfo(false)
            console.log(error)
        })
    }, [state]);

    const selectPaymentMethod = (paymentMethod) => {
        const selectedPaymentMethod = selectedPaymentMethods.find(method => method.id == paymentMethod.id);
        if (selectedPaymentMethod) {
            setSelectedPaymentMethods(selectedPaymentMethods.filter((method => method.id != paymentMethod.id)));
        } else {
            setSelectedPaymentMethods([
                ...selectedPaymentMethods,
                paymentMethod
            ]);
        }
    }

    useEffect(() => {
        const hour = { hour: 23, minute: 59, second: 59, millisecond: 59 }
        if (moment(reverseDatePicker(subscriptionDate)).set(hour).diff(moment().set(hour), 'days') > 0) {
            setApplyChargeDisabled(false);
            setApplyCharge(true);
        } else {
            setApplyChargeDisabled(true);
            setApplyCharge(false);
        }
    }, [subscriptionDate]);

    useEffect(() => {
        setRenovationUnselected(false)

        if (selectedServices.find(item => item.renovation == true) != undefined) {
            setSelectedPaymentType(paymentTypes[0])
            const renovationService = selectedServices.find(item => item.renovation == true)
            selectedServices.map((service) => {
                renovationService.subscription_id == service.subscription_id && !service.renovation && setRenovationUnselected(true)
            })
        }

    }, [selectedServices]);

    useEffect(() => {
        renovationUnselected && setAlert({ active: true, type: 'error', message: "Uno o más servicios no están siendo contemplados en la renovación, ten en cuenta esto antes de generar el link." })

    }, [renovationUnselected]);

    function parseDecimalToInt(input) {
        const num = typeof input === 'string' ? parseFloat(input) : input;
        let result = Math.floor(num);
        if (num < 0) {
            result = Math.ceil(num);
        }
        return result;
    }

    const createLink = async () => {
        setLoading(true);
        const request = {
            "created_by": {
                "name": user.name,
                "email": user.email
            },
            "contract_id": account?.contract_id,
            "account_id": account?.account_id,
            "name": concept,
            "expires_at": moment(reverseDatePicker(serviceDate)).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).valueOf(),
            "allowed_payment_methods": selectedPaymentMethods.map(method => method.value),
            "monthly_installments_enabled": selectedMsi.id != 1 && selectedPaymentType.id != 2,
            ...(selectedMsi.id != 1 && selectedPaymentType.id != 2 && {
                "monthly_installments_options": selectedMsi.values,
            }),
            "order_template": {
                "line_items": selectedServices.filter(item => item.selected == true).map((service) => {
                    return {
                        "id": service.id,
                        "sku": service.sku,
                        "name": service.name,
                        "quantity": parseInt(service.qty),
                        "price": {
                            "subscription_price": service.subscription_price * 100,
                            "unit_price": parseDecimalToInt(service.price),
                            "total_amount": enabledDisccount ? parseDecimalToInt(service.remaining_amount - (service?.discount_value * 100)) : parseDecimalToInt(service.remaining_amount),
                            "discount":  enabledDisccount ?  parseDecimalToInt(service.discount)  : 0,
                            "discount_type": enabledDisccount ? service?.disccount_type : "direct",
                            "discount_amount": enabledDisccount ? parseDecimalToInt(service?.discount_value * 100) : 0
                        },
                        "subscription_renovation": service?.renovation
                    }
                }),
                "currency": "MXN"
            },
            "recurrent": selectedPaymentType.id == 2,
            ...((selectedPaymentType.id == 2 || selectedServices.find((item) => item.renovation == true) != undefined) && {
                "subscription_info": {
                    "name": concept,
                    "start_date": moment(reverseDatePicker(subscriptionDate)).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
                    "interval": selectedPaymentInterval?.interval,
                    "frequency": selectedPaymentInterval?.frequency,
                    "expiry_count": parseInt(numberOfPayments),
                    "apply_charge": applyCharge,
                    "amount": !selectedServices.find((item) => item.renovation == true) ? totalAmount : selectedServices.filter(item => item.renovation == true).reduce((accumulator, currentService) => accumulator + ((currentService?.subscription_price * 100)), 0, 0),
                    "status_after_retry": "unpaid",
                    "currency": "mxn"
                }
            }),
            "notes": comment
           
        }

        try {
            const response = await createPaymentLink(request);
            setLoading(false);
            setPaymentLink(response);
        } catch (e) {
            setLoading(false);
            setAlert({ active: true, type: 'error', message: e.message })
        }
    }

    const copyLinkToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
    }

    const handleCopyLink = (url) => {
        copyLinkToClipboard(url).then(() => {
            setLinkCopied(true);
            setTimeout(() => {
                setLinkCopied(false);
            }, 5000);
        })
    }

    const handleCopyShortLink = (url) => {
        copyLinkToClipboard(url).then(() => {
            setShortLinkCopied(true);
            setTimeout(() => {
                setShortLinkCopied(false);
            }, 5000);
        })
    }

    useEffect(() => {
        setDisabled(
            account == null ||
            selectedServices.filter(item => item.selected == true).find(element => element.subscription_price == 0 ) != undefined ||
            concept == null || concept === "" ||
            selectedServices.filter(item => item.selected == true).length == 0 ||
            selectedPaymentMethods.length == 0 ||
            selectedPaymentType.id == 2 && (
                numberOfPayments == null || numberOfPayments === ""
            ) ||
            isNaN(totalAmount)
        )
    }, [account, concept, selectedServices, selectedPaymentMethods, numberOfPayments, selectedPaymentType, totalAmount]);

    useEffect(() => {
        enabledDisccount ? setTotalAmount(selectedServices.filter(item => item.selected == true).reduce((accumulator, currentService) => accumulator + ((currentService?.remaining_amount / 100) - currentService?.discount_value), 0, 0)) :
            setTotalAmount(selectedServices.filter(item => item.selected == true).reduce((accumulator, currentService) => accumulator + ((currentService?.remaining_amount / 100)), 0));
    }, [selectedServices]);

    useEffect(() => {
        setMsi(getInstalmentsEnabled(totalAmount));
    }, [totalAmount]);



    return (
        <>
            {loading && <SecondaryLoader />}
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <Link to='/payment-links'>
                            <span className="text-2xl text-white font-bold flex items-center cursor-pointer">
                                <span className="text-white opacity-50 text-4xl mr-2"><FontAwesomeIcon icon={faArrowLeft} /></span>
                                <span className="text-lg pr-1">
                                    Crear link de cobro
                                </span>
                            </span>
                        </Link>
                    </div>
                </Nav>
            </div>
            <div className="w-full px-4">
                <div className="w-full mt-20 md:mt-4">
                    <BreadCrumb roadMap={roadMap} />
                </div>
                <div className="w-full hidden md:flex text-3xl text-v2-input-text font-bold mt-5 justify-between">
                    <a >
                        <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => history.goBack()} />
                    </a>
                    <span className="pl-4 w-full">
                        Crear link de cobro
                    </span>
                </div>
                {!paymentLink && (
                    <>
                        <div className="w-full mt-4 flex xs:flex-col lg:flex-row gap-4">
                            <div className='xs:w-full lg:w-3/5 transition-all ease-out duration-400'>
                                <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 lg:mb-4">
                                    <HeadersDataTables icon={faFileInvoiceDollar} text="Datos del cobro" background={true} padding={0} bgColor="bg-v2-blue-text-login" textColor="text-white" />
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Concepto:
                                        <input
                                            type="text"
                                            className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 my-1 rounded-md"
                                            placeholder="Agrega un concepto"
                                            onBlur={(e) => setConcept(e.target.value)}
                                            defaultValue={concept}
                                            autoComplete='off' />
                                    </div>
                                    <PermissionsGate allowedRoles={[ROLES.root, ROLES.collections, ROLES.collections_manager, ROLES.help_desk]}>
                                        <div className='bg-gray-100 mx-4 mt-4 p-4 rounded-lg text-gray-500 font-semibold flex justify-between'>
                                            ¿Otorgar descuento?
                                            <div className='flex items-center gap-2'>
                                                <span className='text-gray-900 font-normal text-sm'>No</span>
                                                <Toogle blue={true} enabled={enabledDisccount} setEnabled={setEnabledDisccount} />
                                                <span className='text-gray-900 font-normal text-sm'>Sí</span>
                                                <TagIcon className='text-green-500 w-5 h-5 inline-block' />
                                            </div>
                                        </div>
                                    </PermissionsGate>
                                    <TableBillingLink loading={loadingInfo} services={services} selectedServices={selectedServices} enabledDisccount={enabledDisccount} setSelectedServices={setSelectedServices} />

                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Tipo de cobro:
                                        <div className='py-1 flex justify-between gap-4 mt-1 text-base'>
                                            {paymentTypes.map((type, i) => (
                                                <div key={i}
                                                    className={`${type.id == selectedPaymentType.id ? 'shadow-lg border-primary bg-primary/10' : 'border-v2-border-time'} select-none transition-all ease-in-out duration-200 w-full relative border  rounded-5 px-2 py-3  font-normal text-center text-v2-input-text flex flex-col items-center justify-center ${(selectedServices.find((item) => item.renovation == true) != undefined && type.id == 2) ? 'bg-gray-100 text-gray-600' : 'bg-white cursor-pointer'}`}
                                                    disabled={type.id == 2 && (selectedServices.find((item) => item.renovation == true) != undefined)}
                                                    onClick={() => {
                                                        if (!selectedServices.find((item) => item.renovation == true)) {
                                                            setSelectedPaymentType(type);
                                                            setSelectedPaymentMethods(selectedPaymentMethods.filter(method => type.allowedPaymentMethods.includes(method.value)))
                                                            if (type.id == 2) {
                                                                setSelectedMsi(msi.find(msi => msi.id == 1));
                                                            }
                                                        }

                                                    }}>
                                                    <div className={`${type.id == selectedPaymentType.id ? 'visible' : 'invisible'}  w-6 h-6 rounded-full bg-white text-2xl text-primary absolute -right-2 -top-3`}>
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </div>
                                                    <span>{type.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {(selectedPaymentType.id == 2 || selectedServices.find((item) => item.renovation == true) != undefined) && (
                                        <>
                                            <div className={`${!selectedServices.find((item) => item.renovation == true) ? 'hidden' : 'block'} w-full px-4 text-sm font-bold text-gray-600 block mt-4 mb-0`}>
                                                Información de la reactivación:
                                            </div>
                                            <div className='w-full px-4 items-center text-xs font-bold text-v2-gray-title-client block xl:flex gap-4'>

                                                <div className='flex w-full gap-4 pt-4'>
                                                    <div className='w-full'>
                                                        Inicio de cobro:
                                                        <div className='w-full relative my-1'>
                                                            <DatePicker
                                                                value={subscriptionDate}
                                                                onChange={setSubscriptionDate}
                                                                locale='en'
                                                                wrapperClassName='block z-10 responsive-calendar'
                                                                inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-1.5 font-normal border border-v2-gray-border-tables rounded-5 text-base text-v2-input-text outline-none responsive-calendar'
                                                                calendarClassName='block font-normal text-v2-input-text'
                                                                calendarPopperPosition='top'
                                                                colorPrimary='#2169AC'
                                                                minimumDate={convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD'))}
                                                            />
                                                            <FontAwesomeIcon icon={faCalendar} className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary' />
                                                        </div>
                                                    </div>
                                                    <div className='w-full'>
                                                        Número de cobros:
                                                        <div className="relative my-1 w-full text-base text-v2-text-bar-steps outline-none flex items-center justify-center">
                                                            <input
                                                                type="text"
                                                                inputMode='number'
                                                                className={`${numberOfPayments == null || numberOfPayments === "" ? 'border-v2-red-error-services-selected focus:ring-v2-red-error-services-selected focus:border-v2-red-error-services-selected' : 'border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login'} font-normal  text-v2-text-bar-steps transition-all block w-full pl-4 pr-6 rounded-md`}
                                                                value={numberOfPayments}
                                                                onChange={(e) => setNumberOfPayments(e.target.value.replace(/\D/g, ""))}
                                                                autoComplete='off'
                                                                maxLength={2} />
                                                            <div className='absolute flex items-center top-0 right-0 bottom-0 text-sm mx-2'>
                                                                <FontAwesomeIcon
                                                                    onMouseEnter={() => setShowTooltip(true)}
                                                                    onMouseLeave={() => setShowTooltip(false)}
                                                                    onClick={() => setShowTooltip(true)}
                                                                    icon={faCircleQuestion}
                                                                    className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-v2-input-text cursor-pointer' />
                                                                <div className="absolute w-28 bottom-12 xs:-right-2 lg:-right-7">
                                                                    <Tooltip show={showTooltip} onClose={() => setShowTooltip(false)} arrowPosition={{ "xs": "right", "lg": "middle" }} message="Número total de cobros que se realizarán. 0 es indefinido" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-full pt-4'>
                                                    Intervalo de cobro:
                                                    <div className='md:flex gap-2'>
                                                        <Select
                                                            onChange={(e) => setSelectedPaymentInterval(paymentInterval.find(interval => interval.name == e.target.value))}
                                                            value={selectedPaymentInterval.name} >
                                                            {paymentInterval.map((interval, i) => (
                                                                <option key={i} value={interval.name}>{interval.name}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !selectedServices.find((item) => item.renovation == true) && <div className='w-full px-4 items-center text-sm font-normal text-v2-input-text'>
                                                    <div className='flex items-center min-w-[7.5rem]'>
                                                        <label className={`${applyChargeDisabled ? 'opacity-60' : 'opacity-100 cursor-pointer'} flex items-start select-none mt-1`}>
                                                            <input disabled={applyChargeDisabled} type="checkbox" checked={applyCharge} onChange={() => setApplyCharge(!applyCharge)} className='mt-1 mr-1 accent-primary' />
                                                            Realizar cobro al suscribir
                                                        </label>
                                                    </div>
                                                </div>
                                            }

                                        </>
                                    )}
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Métodos de pago permitidos:
                                        <div className='py-1 flex flex-col md:flex-row justify-between gap-4 mt-1'>
                                            {paymentMethods.map((paymentMethod, i) => {
                                                const enabled = !selectedServices.find((item) => item.renovation == true) ? selectedPaymentType.allowedPaymentMethods.includes(paymentMethod.value) : paymentMethod.value == "card";
                                                const selected = enabled && selectedPaymentMethods.find(method => method.id == paymentMethod.id) != null;
                                                const noneSelected = enabled && selectedPaymentMethods.length == 0;
                                                let borderStyle = selected ? 'shadow-lg border-primary bg-primary/10' : 'border-v2-border-time';
                                                if (noneSelected) borderStyle = 'border-v2-red-error-services-selected';
                                                return (
                                                    <div key={i}
                                                        onClick={() => {
                                                            if (enabled) selectPaymentMethod(paymentMethod)
                                                        }}
                                                        className={`${enabled ? 'opacity-100 cursor-pointer' : 'opacity-50'} ${borderStyle} select-none transition-all ease-in-out duration-200 w-full relative border bg-white rounded-5 px-2 py-3 text-sm font-normal text-center text-v2-input-text flex flex-col items-center justify-center`}>
                                                        <div className={`${selected ? 'visible' : 'invisible'} w-6 h-6 rounded-full text-2xl text-primary absolute -right-2 -top-3 bg-white`}>
                                                            <div></div>
                                                            <FontAwesomeIcon icon={faCircleCheck} />
                                                        </div>
                                                        {(typeof paymentMethod.icon === 'string') ? (
                                                            <img src={paymentMethod.icon} className='h-8 object-contain' />
                                                        ) : (
                                                            <FontAwesomeIcon className='text-4xl' icon={paymentMethod.icon} />
                                                        )}
                                                        <span className='mt-1'>{paymentMethod.shortName}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {(selectedPaymentMethods.length == 0) && (
                                            <div className='w-full text-sm text-v2-red-error-services-selected flex flex-row items-center mt-1 font-normal'>
                                                <FontAwesomeIcon className='' icon={faTriangleExclamation} />
                                                <span className='ml-2'>Selecciona al menos un método de pago</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='w-full px-4 items-center text-xs font-bold text-v2-gray-title-client block md:flex gap-4'>
                                        <div className='w-full pt-4'>
                                            Meses sin intereses:
                                            <Select
                                                onChange={(e) => setSelectedMsi(msi?.find(msi => msi.id == e.target.value))}
                                                value={selectedMsi?.id}
                                                disabled={selectedPaymentType.id == 2 || totalAmount < 1500}>
                                                {msi.map((msi, i) => (
                                                    <option key={i} value={msi.id}>{msi.title}</option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='w-full pt-4'>
                                            Fecha de caducidad:
                                            <div className='w-full relative my-1'>
                                                <DatePicker
                                                    value={serviceDate}
                                                    onChange={setServiceDate}
                                                    locale='en'
                                                    wrapperClassName='block z-10 responsive-calendar'
                                                    inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-1.5 font-normal border border-v2-gray-border-tables rounded-5 text-base text-v2-input-text outline-none responsive-calendar'
                                                    calendarClassName='block font-normal text-v2-input-text'
                                                    calendarPopperPosition='top'
                                                    colorPrimary='#2169AC'
                                                    minimumDate={convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD'))}
                                                />
                                                <FontAwesomeIcon icon={faCalendar} className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary' />
                                            </div>
                                        </div>
                                    </div>
                                    {enabledDisccount &&
                                        <div className='w-full px-4 items-center text-sm text-gray-800 block md:flex gap-4'>

                                            <div className="mt-2 w-full">
                                                <textarea
                                                    onChange={(e) => setComment(e.target.value)}
                                                    id="comment"
                                                    name="comment"
                                                    rows={4}
                                                    className="block bg-[#F1F1F1] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                                                    defaultValue={''}
                                                    placeholder='Agrega comentarios del motivo del descuento (300 caracteres)'
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className='xs:w-full lg:w-2/4'>
                                <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 mb-4">
                                    <HeadersDataTables icon={faCartShopping} text="Resumen del cobro" background={true} padding={0} bgColor="bg-button-orange" textColor="text-white" />

                                    <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Cliente:
                                        {account ? <span className='text-base font-normal block text-v2-input-text'>{account?.name}</span> : <span className='w-full'>
                                            <SkeletonLoader />   </span>}

                                    </div>
                                    <div className="w-full px-4 xs:block lg:flex items-center text-xs font-bold text-v2-gray-title-client gap-2">
                                        <div className='xs:w-full lg:w-3/5 xl:w-1/2 text-xs font-bold text-v2-gray-title-client pt-3'>
                                            Correo:
                                            {account ? <span className='text-base font-normal block text-v2-input-text'>{account?.email}</span> : <span className='w-full'>
                                                <SkeletonLoader />   </span>}

                                        </div>
                                        <div className='xs:w-full lg:w-2/5 xl:w-1/2 text-xs font-bold text-v2-gray-title-client pt-3'>
                                            Móvil:
                                            {account ? <span className='text-base font-normal block text-v2-input-text'>{account?.phone_number}</span> : <span className='w-full'>
                                                <SkeletonLoader />   </span>}

                                        </div>
                                    </div>
                                    <div className='w-full px-4 mt-3'>
                                        <div className='border-t border-dashed border-v2-gray-border-tables' />
                                    </div>

                                    {concept && concept != "" && (
                                        <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                            Concepto:
                                            <div className='flex justify-between text-v2-input-text'>
                                                <span className='text-base leading-snug font-normal block'>{concept}</span>
                                            </div>
                                        </div>
                                    )}
                                    {selectedServices.length == 0 && (
                                        <>
                                            <div className='w-full px-4 pt-5 items-center gap-3 flex-col text-xs font-bold text-v2-gray-title-client'>
                                                Servicios seleccionados:
                                                <div className='flex flex-col gap-2'>
                                                    <div className='flex justify-between text-v2-input-text'>
                                                        <span className='w-1/2'><SkeletonLoader /></span>
                                                        <span className='w-1/4'><SkeletonLoader /></span>

                                                    </div>
                                                    <div className='flex justify-between'>
                                                        <span className='w-1/3'><SkeletonLoader /></span>
                                                        <span className='w-1/5'><SkeletonLoader /></span>
                                                    </div>
                                                    <div className="w-2/3">
                                                        <SkeletonLoader />
                                                    </div>
                                                </div>
                                                <div className='flex flex-col gap-2 mt-3'>
                                                    <div className='flex justify-between text-v2-input-text'>
                                                        <span className='w-1/2'><SkeletonLoader /></span>
                                                        <span className='w-1/4'><SkeletonLoader /></span>

                                                    </div>
                                                    <div className='flex justify-between'>
                                                        <span className='w-1/3'><SkeletonLoader /></span>
                                                        <span className='w-1/5'><SkeletonLoader /></span>
                                                    </div>
                                                    <div className="w-2/3">
                                                        <SkeletonLoader />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-full px-4 mt-3'>
                                                <div className='border-t border-dashed border-v2-gray-border-tables' />
                                            </div>
                                        </>
                                    )}
                                    {selectedServices.length > 0 && (
                                        <>
                                            <div className='w-full px-4 pt-5 items-center text-xs font-bold text-v2-gray-title-client'>
                                                Servicios seleccionados:
                                                {selectedServices.filter(item => item.selected == true).map((service, i) => (
                                                    <div key={i} className={i == 0 ? 'pt-1' : 'pt-2'}>
                                                        <div className='flex justify-between text-v2-input-text'>
                                                            <span className='text-base leading-snug font-normal block'>{service.name}</span>
                                                            {
                                                                enabledDisccount ?
                                                                    <>
                                                                        <span className='text-base leading-snug font-normal block ml-4'>{isNaN(service?.remaining_amount / 100) ? formatterCurrency(0) : formatterCurrency((service?.remaining_amount / 100) - service?.discount_value)}</span>
                                                                    </> :
                                                                    <>
                                                                        <span className='text-base leading-snug font-normal block ml-4'>{isNaN(service?.remaining_amount / 100) ? formatterCurrency(0) : formatterCurrency((service?.remaining_amount / 100))}</span>
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className='flex justify-between -mt-1'>
                                                            <span className='text-sm font-normal block text-v2-gray-title-client'>Cantidad: {service.qty}</span>
                                                            {
                                                                enabledDisccount ?
                                                                    <>
                                                                        <span className='text-sm font-normal block text-v2-gray-title-client ml-4'><del>{formatterCurrency(service?.remaining_amount / 100)}</del></span>
                                                                    </> :
                                                                    <>
                                                                        {service.qty > 1 && (
                                                                            <span className='text-sm font-normal block text-v2-gray-title-client ml-4'>{isNaN(service?.price / 100) ? formatterCurrency(0) : formatterCurrency(service?.price / 100)} c/u</span>
                                                                        )}
                                                                    </>
                                                            }

                                                        </div>
                                                        <div className='flex justify-between -mt-1'>

                                                        </div>
                                                        <div className="w-full mt-1">

                                                            <div key={i} className="w-full flex gap-2 flex-wrap max-w-[100%]">

                                                                {service?.dates_display?.map((date, j) => (
                                                                    <div key={j} className="w-auto bg-v2-blue-text-login/20 text-v2-blue-text-login py-2 text-xs rounded flex items-center px-2">
                                                                        <div className="w-full text-center min-w-[40px]">
                                                                            {date.tag}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            service?.renovation && (
                                                                <div className='mt-3 mb-2  px-4 py-3 text-sm flex gap-2 items-center text-primary bg-blue-50 rounded-xl font-semibold'>
                                                                    <InformationCircleIcon className='w-6 h-6' />
                                                                    Se reactivará la suscripción de pago {selectedPaymentInterval?.name.toLowerCase()} a precio regular de {formatPeso(service?.subscription_price)}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='w-full px-4 mt-3'>
                                                <div className='border-t border-dashed border-v2-gray-border-tables' />
                                            </div>
                                        </>
                                    )}

                                    {selectedPaymentMethods.length > 0 && (
                                        <>
                                            <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                                Métodos de pago:
                                                {selectedPaymentMethods.sort((a, b) => a.id - b.id).map((paymentMethod, i) => (
                                                    <div key={i} className="pt-2 w-full flex flex-row items-center justify-between text-v2-input-text">
                                                        <div className='flex'>
                                                            <div className='bg-v2-input-text w-1 h-1 rounded-full mt-2 mr-1' />
                                                            <div>
                                                                <span className='text-base font-normal block leading-snug'>{paymentMethod.name}</span>
                                                                {selectedMsi.id && selectedMsi.id != 1 && paymentMethod.id == 1 && (
                                                                    <span className='text-sm font-normal block text-v2-gray-title-client -mt-1'>{selectedMsi.name}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <img src={paymentMethod.imageUrl} alt={paymentMethod.name} className="h-5" />
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Fecha de caducidad:
                                        <div className='flex justify-between text-v2-input-text'>
                                            <span className='text-base leading-snug font-normal block'>{capitalize(formatDate(reverseDatePicker(serviceDate), 'dddd, DD MMMM YYYY'))}</span>
                                        </div>
                                    </div>
                                    {(selectedPaymentType.id == 2 || selectedServices.find((item) => item.renovation == true) != undefined) && (
                                        <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                            {!selectedServices.find((item) => item.renovation == true) ? 'Primer cobro:' : 'Primer cobro de la suscripción:'}
                                            <div className='flex justify-between text-v2-input-text'>
                                                <span className='text-base leading-snug font-normal block'>{capitalize(formatDate(reverseDatePicker(subscriptionDate), 'dddd, DD MMMM YYYY'))}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className='w-full px-4 mt-4'>
                                        <div className='w-full text-xl p-4 font-bold border border-dashed border-v2-gray-border-tables rounded-b-md items-center flex flex-col justify-center'>
                                            <span className='text-v2-input-text'>Total:</span>
                                            <span className='block text-button-orange'>
                                                {formatterCurrency(isNaN(totalAmount) ? 0 : (totalAmount))}
                                            </span>
                                            {selectedPaymentType.id == 2 && (
                                                <span className='text-v2-input-text text-sm font-normal'>Facturación {selectedPaymentInterval.name.toLowerCase()}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full mt-3 mb-4'>
                                    <Button
                                        disabled={disabled}
                                        onClick={createLink}
                                        heigth='h-11'>
                                        Crear link
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {paymentLink &&
                    <div className='w-full  border border-v2-gray-border-tables rounded-10 pt-6 pb-4 mt-3 bg-white flex flex-col'>
                        <div className="w-full flex xs:flex-col md:flex-row">
                            <div className="xs:w-full md:w-7/12 px-4">
                                <div className="w-full xs:text-center md:text-left text-v2-border-alert-success text-1.5xl font-bold leading-8 tracking-wide">
                                    ¡Felicidades!
                                    <span className="block">
                                        Terminaste el proceso.
                                    </span>
                                </div>
                                <div className="w-full xs:text-center md:text-left text-base text-v2-input-text opacity-60 leading-4 pt-2">
                                    Ahora podrás enviarle este link de cobro al cliente
                                </div>
                                <div className='xs:w-full lg:w-4/6 mt-4 border border-dashed border-v2-gray-border-tables rounded-10 p-4'>
                                    <div className='text-v2-input-text text-base font-semibold'>
                                        {concept}
                                        <span className='pl-1 text-button-orange block text-2xl font-bold'>
                                            {formatterCurrency(totalAmount)}
                                        </span>
                                    </div>
                                    <div className='text-v2-input-text opacity-60'>
                                        Vence el
                                        <span className='pl-1 font-semibold'>
                                            {formatDate(reverseDatePicker(serviceDate), 'dddd, DD MMMM YYYY')}
                                        </span>
                                    </div>
                                    <div className='w-full mt-3'>
                                        <div className='border-t border-dashed border-v2-gray-border-tables' />
                                    </div>
                                    <div className='text-v2-input-text opacity-60 mt-3'>
                                        Cliente:
                                        <span className='pl-1 font-semibold'>
                                            {account?.name}
                                        </span>
                                    </div>
                                    <div className='text-v2-input-text opacity-60 pt-1'>
                                        Correo:
                                        <span className='pl-1 font-semibold'>
                                            {account?.email}
                                        </span>
                                    </div>
                                    <div className='text-v2-input-text opacity-60 pt-1'>
                                        Móvil:
                                        <span className='pl-1 font-semibold'>
                                            {account?.phone_number}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="xs:w-full md:w-5/12 px-4">
                                <div className="w-full flex flex-col justify-center items-center">
                                    <div className="w-80 xs:hidden md:block">
                                        <img
                                            src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/fixi_thankyou.png"
                                            alt="Fixi - Thank you"
                                        />
                                    </div>
                                    <div className='w-full mt-4'>
                                        <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2">
                                            <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                                Link corto:
                                                <input className="block text-base font-normal text-v2-input-text font-normal outline-none w-full" value={paymentLink.short_url} readOnly />
                                            </div>
                                            <div className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={() => handleCopyShortLink(paymentLink.short_url)}>
                                                <FontAwesomeIcon icon={faCopy} />
                                            </div>
                                        </div>
                                        <div className='w-full h-6'>
                                            {shortLinkCopied && (
                                                <span className='text-base text-v2-green-message-copied'>Link copiado con éxito</span>
                                            )}
                                        </div>
                                        <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2 mt-1">
                                            <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                                Link:
                                                <input className="block text-base text-v2-input-text font-normal outline-none w-full" value={paymentLink.url} readOnly />
                                            </div>
                                            <div className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={() => handleCopyLink(paymentLink.url)}>
                                                <FontAwesomeIcon icon={faCopy} />
                                            </div>
                                        </div>
                                        <div className='w-full h-6'>
                                            {linkCopied && (
                                                <span className='text-base text-v2-green-message-copied'>Link copiado con éxito</span>
                                            )}
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className='w-full mt-4 px-4'>
                            <div className='mt-3 mb-2  px-4 py-3 text-sm flex gap-2 items-center text-primary bg-blue-50 rounded-xl font-semibold'>
                                <InformationCircleIcon className='w-6 h-6' />
                                Una vez que el contribuyente pague este link se reactivarán sus pagos automatizados de {formatPeso(!selectedServices.find((item) => item.renovation == true) ? totalAmount : selectedServices.filter(item => item.renovation == true).reduce((accumulator, currentService) => accumulator + ((currentService?.subscription_price)), 0, 0))} a pesos {selectedPaymentInterval?.name.toLowerCase()}
                            </div>
                        </div>
                    </div>

                }
            </div>
        </>
    )
}

export default CreatePaymentBilling;