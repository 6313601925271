import { useEffect, useState } from "react";
import { getOrder } from "../../apiClient/operations/ordersOperations";

import useFormatDate from "../useFormatDate";
import useFormatterCurrency from "../useFormatterCurrency";

export function useOrder({ orderId }) {
  const [order, setOrder] = useState(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [errorOrder, setErrorOrder] = useState(false);

  const { formatterCurrency } = useFormatterCurrency();
  const { formatDate } = useFormatDate();

  const getTransformedData = (data) => {
    return {
      amount: formatterCurrency(data.price.total_amount / 100),
      date: formatDate(new Date(data.created), "D MMMM YYYY, h:mm a"),
    };
  };

  const getAppointmentFormatted = async () => {
    try {
      setIsLoadingOrder(true);
      const response = await getOrder(orderId);
      const transformedData = getTransformedData(response);
      setOrder(transformedData);
    } catch (error) {
      console.log("GET APPOINTMENT ERROR: ", error);
      setErrorOrder(error.message);
    } finally {
      setIsLoadingOrder(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getAppointmentFormatted();
    }
  }, [orderId]);

  return { order, isLoadingOrder, errorOrder };
}
